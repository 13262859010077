import React from 'react'
import RangeSlider from "react-range-slider-input";
export const SliderInput = (props) => {
  const onChange = (e) => {
    console.log(e)
    props.onChange(props.name, e[1])
  }
  return (
    <>{props.value}
      <RangeSlider
        className="single-thumb"
        value={[0, props.value || 0]}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
        onInput={onChange}
        max={500}
        min={100}
      />
    </>
  )
}