import React from "react";
import {
  CInput, CTextarea,
  CInputGroup, CInputGroupAppend,
  CFormGroup, CLabel
} from '@coreui/react'
import { useTranslation } from 'react-i18next';

import InputColor from 'react-input-color';
import NumericInput from "react-numeric-input";
const TextBox = (props) => {
  const { t } = useTranslation();
  const onChange = (e) => {
    const { value } = e.target;
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return <CInput
    type="text"
    onChange={onChange}
    value={props.value}
    placeholder={t(props.placeholder)}
    name={props.name}
  />
}
const NumberBox = (props) => {
  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return <NumericInput
    onChange={onChange}
    className="form-control"
    name={props.name}
    value={props.value}
    min={props.min || 0}
    max={props.max || 100}
    step={1}
    precision={0}
    size={5}
    mobile
  />
}
const ColorBox = (props) => {
  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return <CInputGroup className={'color-input-group'}>
    <CInput autoComplete="off" {...props} onChange={(e) => onChange(e.target?.value)} />
    <CInputGroupAppend>
      <InputColor
        name={props.name}
        initialValue={props.value || ''}
        onChange={(e) => onChange(e.hex)}
        placement="right"
      />
    </CInputGroupAppend>
  </CInputGroup>
}
const TextAreaBox = (props) => {
  const onChange = (e) => {
    const { value } = e.target;
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return <CTextarea
    onChange={onChange}
    rows="2"
    value={props.value}
    name={props.name}
  />
}
export const TextBoxInput = (props) => {
  const onChange = (value) => {
    if (props.onChange) {
      props.onChange(props.name, value)
    }
  }
  return (
    <CFormGroup>
      <CLabel htmlFor={props.name}>{props.label || props.name}</CLabel>
      <div className="text-box-input">
        {props.type === 'text' && <TextBox name={props.name} value={props.value} onChange={onChange} />}
        {props.type === 'number' && <NumberBox name={props.name} value={props.value} onChange={onChange} />}
        {props.type === 'color' && <ColorBox name={props.name} value={props.value} onChange={onChange} />}
        {props.type === 'textarea' && <TextAreaBox name={props.name} value={props.value} onChange={onChange} />}
      </div>
    </CFormGroup>
  )
}