import React, { useEffect, useRef, useState } from "react";
import { QRCodeExt } from "src/plugins/qrCode";
import {
  SliderInput
} from "src/_components";
import { cssToObj } from "src/_helpers"
export const QRCode = ({ value, size, gid, color, image }) => {
  const ref = useRef(null);
  useEffect(() => {
    const qrCode = new QRCodeExt({
      width: size || 300,
      height: size || 300,
      // image: `/b8b69c0d-bae9-40e2-9d69-20ed60912422.png`,
      image: image ? image : (gid ? `${process.env.REACT_APP_API_URL}static/uploads/${gid}.png` : null),
      type: 'svg',
      gid: gid,
      id: gid,
      dotsOptions: {
        color: color || "#4267b2",
        type: "rounded"
      },
      imageOptions: {
        margin: 0,
        imageSize: 0.4,
        crossOrigin: "anonymous"
      },
      qrOptions: {
        hideBackgroundDots: true
      },
      data: value,
      errorCorrectionLevel: 'L'
    });
    qrCode.update({ gid })
    qrCode.append(ref.current);
  }, []);
  return (
    <>
      <div className="qr-warp" ref={ref} />
    </>
  )
}
export const QRCodeNoImage = ({ value, size, gid, color, image }) => {
  const ref = useRef(null);
  const qrCode = new QRCodeExt({
    width: size || 300,
    height: size || 300,
    // image: `/b8b69c0d-bae9-40e2-9d69-20ed60912422.png`,
    type: 'svg',
    gid: gid,
    id: gid,
    dotsOptions: {
      color: color || "#4267b2",
      type: "rounded"
    },
    qrOptions: {
      hideBackgroundDots: true
    },
    data: value,
    errorCorrectionLevel: 'L'
  });
  useEffect(() => {
    qrCode.update({ gid })
    qrCode.append(ref.current);
  }, []);
  return (
    <>
      <div className="qr-warp" ref={ref} />
      <p className="pointer" onClick={() => qrCode.download({
        extension: 'png',
        name: gid,
      })}>Tải qr</p>
    </>
  )
}
// export const QREditor = ({ value, size, gid, color, image }) => {
//   const [qrSize, setSize] = useState(styleString);
//   const ref = useRef(null);
//   const qrCode = new QRCodeExt({
//     width: size || 300,
//     height: size || 300,
//     // image: `/b8b69c0d-bae9-40e2-9d69-20ed60912422.png`,
//     type: 'svg',
//     gid: gid,
//     id: gid,
//     dotsOptions: {
//       color: color || "#4267b2",
//       type: "rounded"
//     },
//     qrOptions: {
//       hideBackgroundDots: true
//     },
//     data: value,
//     errorCorrectionLevel: 'L'
//   });
//   useEffect(() => {
//     qrCode.update({ gid })
//     qrCode.append(ref.current);
//   }, []);
//   const onChange = (_, e) => {
//     console.log(cssToObj(e))
//   }
//   return (
//     <>
//       <div className="qr-warp" ref={ref} />
//       <SliderInput value={qrSize} onChange={(e, f) => this.onHandleChange(e, f)} name="qrSize" />
//       <p className="pointer" onClick={() => qrCode.download({
//         extension: 'png',
//         name: gid,
//       })}>Tải qr</p>
//     </>
//   )
// }
