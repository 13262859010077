import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { UploadService } from 'src/_services'
import {
  CFormGroup, CLabel
} from '@coreui/react'
import {
  getAppConfig,
  formatBytes,
  retrieveImageFromClipboardAsBlob
} from 'src/_helpers'
const MAXSIZE = 1 // MB
const uploadFile = (file, callback, fail) => {
  return new UploadService().upload(file).then((fileUploaded) => {
    callback(fileUploaded);
  })
    .catch((e) => {
      fail(e)
    })
}

export const TinymceCEditor = props => {
  const [defaultValue, setValue] = useState(props.value);
  var maxFileSize = getAppConfig('UPLOADFILESIZE')
  if (maxFileSize) {
    maxFileSize = parseInt(maxFileSize)
  }
  const applyCallback = value => {
    setValue(value)
    if (props.onChange)
      props.onChange(props.name, value ? value.replaceAll('"static/uploads/', '"https://imonqr.com/static/uploads/') : value);
  };
  return (
    <CFormGroup>
      <CLabel htmlFor={props.name}>{props.label || props.name}</CLabel>
      <div className="text-box-input">
      {props.readonly ?
        <div className="fake-form-control">{defaultValue || 'NA'}</div>
        :
        <Editor
          value={defaultValue || '<div></div>'}
          apiKey="masv3v7kgoord7oy94zu6gu0kvi0ii963d335m0ezatvtx5p"

          init={{
            setup: (editor) => {
              editor.on('paste', (e) => {
                var imageBlob = retrieveImageFromClipboardAsBlob(e);
                if (!imageBlob) {
                  return;
                }
                if (imageBlob.size / (1024 * 1024) > (maxFileSize || MAXSIZE)) {
                  alert(`File size is ${formatBytes(imageBlob.size)}, max is ${maxFileSize || MAXSIZE}MB`)
                  return;
                }
                e.preventDefault();
                uploadFile(imageBlob, response => {
                  if (response && editor) {
                    editor.insertContent('<img class="_post_img" src="https://imonqr.com' + response + '" />');
                  }
                }, () => {
                  alert('Có lỗi xảy ra, vui lòng thử lại')
                });
              })
            },
            automatic_uploads: false,
            height: 500,
            menubar: false,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');@font-face { font-family: 'GothamBold'; src: url('/static/fonts/SVN-Gotham.woff2') format('woff2'), url('/static/fonts/SVN-Gotham.woff') format('woff'); font-weight: 500; font-style: normal; font-display: swap; } @font-face { font-family: 'GothamLight'; src: url('/static/fonts/SVN-GothamLight.woff2') format('woff2'), url('/static/fonts/SVN-GothamLight.woff') format('woff'); font-weight: 300; font-style: normal; font-display: swap; }",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              "code preview"
            ],
            font_formats:
              "GothamLight=GothamLight;GothamBold=GothamBold;Fira Sans=Fira Sans;Allison=Allison;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            toolbar:
              "undo redo | styleselect | fontsizeselect fontselect | bold italic | forecolor backcolor image | bullist numlist outdent indent | preview | help"
          }}
          onEditorChange={applyCallback}
        />
      }
      </div>
    </CFormGroup>
  );
};
